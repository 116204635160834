import React from "react"
import { Link } from "gatsby"
import { get } from "lodash"
import { LazyLoadImage } from "react-lazy-load-image-component"
import SliderMain from "./slider-main"
import { StaticQuery, graphql } from "gatsby"
import Router from "../domain/router"
import Breadcrumb from "./breadcrumb"

const BlogTopBannerContainer = ({ mainPage }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost(
            sort: {fields: date, order: DESC},
            filter: {acf: {spotlight: {eq: true}, banner_image: {url: {ne: null}}}}
          ) {
            nodes {
              ...PostItem
            }
          }
        }
      `}
      render={data => {
        return <BlogTopBanner mainPage={mainPage} posts={data.allWordpressPost.nodes} />
      }}
    />
  )
}

const BlogTopBanner = ({ posts, mainPage }) => {
  const slides = posts.map((post, index) => ({
    name: post.name,
    content: <SlideContent post={post} mainPage={mainPage} index={index} />
  }))

  return (
    <div className="blog-top-banner-component">
      <div className="container">
        <SliderMain slides={slides} theme="white" />
      </div>
    </div>
  )
}

const SlideContent = ({ post, mainPage, index }) => {
  const primary_category = 'blog-top-banner category-' + get(post, "primary_category.slug")
  const banner_src = get(post, "acf.banner_image.sizes._1400_500_crop")
  const banner_title = get(post, "acf.banner_title")
  const breadcrumbItems = [
    { label: "Home", link: "/" },
    { label: get(mainPage, "acf.page_title"), link: null }
  ]

  return (
    <div className="slider-item has-background-yellow">
      <div className="slide-mobile-content is-hidden-tablet">
        <LazyLoadImage src={banner_src} alt={banner_title} className="slider-bg" />
        <div className="slide-mobile-content-info is-padding-horizontal-4 is-padding-top-8 is-padding-bottom-12">
          <Breadcrumb theme="white" items={breadcrumbItems} />
          <div className="is-size-3 has-text-weight-bold has-text-white is-margin-bottom-4">
            {post.title}
          </div>
          <Link to={Router.blogPostPath(post)} id={`banner-blog-${index}`} className={`button is-rounded is-carnation ${primary_category}`}>
            <strong>Ler mais</strong>
          </Link>
        </div>
      </div>

      <div className="columns is-gapless is-hidden-mobile">
        <div className="column is-8 is-relative">
          <LazyLoadImage src={banner_src} alt={banner_title} className="slider-bg" />
          <div className="slider-fade"></div>

          <div className="slide-left-info">
            <Breadcrumb theme="white" items={breadcrumbItems} />
            <div>
              <h2 className="is-size-2 has-text-weight-bold has-text-white">
                As mais<br />
                lidas do blog
              </h2>
            </div>
          </div>
        </div>

        <div className="column is-4 has-background-yellow">

          <div className="is-padding-horizontal-8 is-padding-vertical-10">
            <h2 className="is-size-3 has-text-weight-bold is-margin-bottom-8 has-text-black">
              {post.title}
            </h2>
            <p className="is-margin-bottom-8 is-font-size-20 has-text-black">
              {post.excerpt}
            </p>
            <Link to={Router.blogPostPath(post)} id={`banner-blog-${index}`} className={`button is-rounded is-carnation ${primary_category}`}>
              <strong>Ler mais</strong>
            </Link>
          </div>

        </div>
      </div>
    </div>
  )
}

export default BlogTopBannerContainer
