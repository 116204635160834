import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogContent from "../components/blog-content"
import BlogTabs from "../components/blog-tabs"
import BlogGrid from "../components/blog-grid"
import HomeContact from "../components/home-contact"
import BlogTopBanner from "../components/blog-top-banner"

const Page = ({ data, pageContext }) => {
  const posts = data.posts.nodes
  const mainPage = data.mainPage

  return (
    <Layout>
      <SEO post={mainPage} />
      <BlogTopBanner mainPage={mainPage} />
      <BlogTabs type="post" active="all" />
      <BlogContent>
        <BlogGrid posts={posts} pageContext={pageContext} />
      </BlogContent>
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    posts: allWordpressPost(
      filter: {status: {eq: "publish"}},
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: date_gmt}
    ) {
      nodes {
        ...PostItem
      }
    }

    mainPage: wordpressMainPage(name: {eq: "blog-e-receitas"}) {
      ...MainPageFull
    }
  }
`
